import React from "react";
import createGuest from "cross-domain-storage/guest";


const bazStorage = createGuest('https://localhost:3001');
export const userCheck = () => {
    let userEmail = localStorage.getItem('userEmail')
    if (userEmail !== 'null'){
        console.log(userEmail)
        return userEmail
    }else {
        bazStorage.get('credd', function(error, value) {
            if (error){
                console.log(error)
            }else {
                console.log(value)
            }
        });

    }
}
