import './App.scss';
import Homepage from "./Pages/HomePage";
import {ParallaxProvider} from "react-scroll-parallax";
import { BrowserRouter as Router } from 'react-router-dom';
import routes, { renderRoutes } from './routes';
import React from "react";

const App = () => {
    const BASENAME = ''
  return (
      <Router basename={BASENAME}>
          <ParallaxProvider>
              {renderRoutes(routes)}
          </ParallaxProvider>
      </Router>

  )
}

export default App
