import React from "react";
import Header from "../components/headerElement";
import Footer from "../components/footerElement"

 const TermsPage = () => {
  const styles = {
   header:{
     marginLeft: 50,
     marginRight: 50,
   },
    termsContainer:{
      margin: 50,
    }
  }
    return (
        <div>
         <div style={styles.header}>
          <Header/>
         </div>

        <div style={styles.termsContainer}>
     <p>1. DEFINITIONS</p>



     <p>The following definitions apply</p>



     <p>•&nbsp; &nbsp; "Customer" shall mean the person (including corporate entities or any other legal person) that we are contracting with for the sale of goods or services and in the case of ongoing services, the person receiving those services.</p>



     <p>•&nbsp; &nbsp; "General Terms and Conditions" means these terms and conditions</p>



     <p>•&nbsp; &nbsp; "Specific Terms and Conditions" means the Specific terms and conditions tailored to cover any product or service that you may purchase through the Website, and which take priority over these General Terms and Conditions to the extent of any conflict between them.</p>



     <p>•&nbsp; &nbsp; "Web site" "Website" or "Site" means the website you are browsing when you clicked on a link to these General Terms and Conditions, including all subsidiary pages.</p>



     <p>•&nbsp; &nbsp; "we" or "us" or "our" or "ourselves" refers to FoodNear Company</p>



     <p>2. INFORMATION CONTAINED ON THE WEBSITE</p>



     <p>•&nbsp; &nbsp; Whilst we take all reasonable care to ensure that the information contained on the Website is accurate and up to date, we make no representations, warranties or undertakings about any of the information content or materials provided on the Website (including, without limitation, any as to quality, accuracy, completeness or reliability).</p>



     <p>•&nbsp; &nbsp; All material on the Website is provided for information purposes only and does not constitute legal, accounting or other professional advice, and it must therefore not be relied upon as such. You should arrange your own advice from a qualified party before acting in reliance on any of the information, or purchasing any of the products or services, available on or from the Website.</p>



     <p>3. UPDATES AND CHANGES</p>



     <p>•&nbsp; &nbsp; The Website is being updated and improved on an ongoing basis. We reserve the right to change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm that we shall not be liable to you for any such change or removal; and</p>



     <p>•&nbsp; &nbsp; Changes to these General Terms and Conditions or to the Specific Terms and Conditions may be made at any time and your use of the Website, or the purchase of products or services, are subject to any such changes. You agree to check to see if any changes have been made to the General or the relevant Specific terms each time you visit the Website or purchase products or services from it.</p>



     <p>4. EXCLUSION OF LIABILITY TO YOU FROM THE USE OF THE WEBSITE</p>



     <p>•&nbsp; &nbsp; The Website is provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>



     <p>•&nbsp; &nbsp; Any and all liability to you that may arise from your access to and use of the Website, whether due to negligence, breach of duty or otherwise, is excluded to the maximum extent permitted by law.</p>



     <p>•&nbsp; &nbsp; No warranty is given that the functionality of the Website will be uninterrupted or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or destructive.</p>



     <p>•&nbsp; &nbsp; We are not responsible for the content of other Websites that link to the Website, nor are we responsible for the content of any Website to which links are provided from the Website. Links to other sites are provided purely for your convenience and do not imply that we approve of those sites.</p>



     <p>•&nbsp; &nbsp; Nothing in these General Terms and Conditions shall be construed so as to exclude or limit the liability of ourselves for death or personal injury as a result of our negligence or that of its employees or agents.</p>



     <p>5. COPYRIGHT AND TRADE MARKS (INTELLECTUAL PROPERTY)</p>



     <p>•&nbsp; &nbsp; The copyright in all materials on the Website, including their design, layout, text, graphics, photographs and the source code and software belong to their respective owners. Trade marks (whether registered or not) company names and the like are the property of their respective owners.</p>



     <p>•&nbsp; &nbsp; You are licensed to view and temporarily store Website pages and their content in your browser’s temporary cache, and also to print out for reference a single copy for non-commercial purposes and off-line review. You may not sell or re-sell anything available from the Website, save to the extent expressly permitted pursuant to any product or service purchased by you from the Website where such permission is either expressly given or is a necessary attribute of the product or service concerned.</p>



     <p>6. FORCE MAJEURE – SUPPLY OF GOODS OR SERVICES ORDERED THROUGH THE WEBSITE</p>



     <p>•&nbsp; &nbsp; In connection with the supply of any goods or services ordered by you through the Website, we shall not be liable for any delay or failure to perform any of our obligations if the delay or failure results from events or circumstances outside our reasonable control, including but not limited to acts of God, strikes, lock outs, accidents, war, fire or failure of any communications, telecommunications or computer system, and we shall be entitled to a reasonable extension of our obligations to you (to the extent we owe any such obligations) should a force Majeure event occur.</p>



     <p>•&nbsp; &nbsp; If a Force Majeure event to which this clause applies shall occur, we agree to notify you as soon as practicable. If the Force Majeure event continues for more than 14 days, either party shall have the right to cancel the agreement and where services have been paid for in advance but have not been rendered, you will be entitled to a refund from the date of cancellation for all such services.</p>



     <p>7. USER NAME AND PASSWORD</p>



     <p>•&nbsp; &nbsp; The website may provide the facility to register in order to gain enhanced access privileges or in order to purchase products or services. If you register, it is your responsibility to maintain the confidentiality of your password. On no account should you disclose your password to anyone else. You agree to indemnify and hold us harmless for any loss or damage we may incur resulting from breach of this clause.</p>



     <p>8. DATA PROTECTION</p>



     <p>• We take our obligations of confidentiality and the protection of personal data very seriously. We will not, therefore, sell or make personal data available to any third party without your prior consent.</p>



     <p>TRANSACTIONS WITH CUSTOMERS PLACING AN ORDER ON THEIR OWN BEHALF</p>



     <p>•&nbsp; &nbsp; Where you request the our services for your personal benefit and not as a professional intermediary on behalf of others:</p>



     <p>•&nbsp; &nbsp; For the purposes of applicable data protection legislation, we will process any personal data you have provided to us in accordance with our Privacy Policy available on our website or on request from us. If you have any queries about the manner in which personal data will be processed by us or your rights in relation to such processing you should contact us via our contact form or on our telephone number, both of which are accessible on this website.</p>



     <p>• &nbsp; &nbsp; You agree that, if you have provided to us personal data relating to a third party (1) you have in place all necessary appropriate consents and notices to enable lawful transfer of such personal data to us and (2) that you have brought to the attention of any such third party the Privacy Policy available on our website or otherwise provided a copy of it to the third party. You agree to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>TRANSACTIONS WITH PROFESSIONAL INTERMEDIARIES</p>



     <p>•&nbsp; &nbsp; Where you are request our services on behalf of others:</p>



     <p>•&nbsp; &nbsp; It is agreed that both parties will comply with the Data Protection Legislation and that this clause is in addition to, and does not replace, a party's obligations under the Data Protection Legislation. Data Protection Legislation means: (i) unless and until the GDPR is no longer directly applicable in the UK, the General Data Protection Regulation ((EU) 2016/679) and any national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK and then (ii) any successor legislation to the GDPR or the Data Protection Act 1998.</p>



     <p>•&nbsp; &nbsp; The parties acknowledge that for the purposes of the Data Protection Legislation, you are the data controller and we are the data processor.</p>



     <p>•&nbsp; &nbsp; Notwithstanding b. above, the parties acknowledge that for the purposes of the Data Protection Legislation, where ongoing services are being provided by us to your customer, that we are each data controllers.</p>



     <p>•&nbsp; &nbsp; You will ensure that you have all necessary appropriate consents and notices in place to enable lawful transfer of the Personal Data to us for the duration and purposes of this agreement. You agree to provide us with such evidence as we require in relation to your compliance with these requirements and, further, to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>•&nbsp; &nbsp; Details of the Personal Data that we process as a Data Processor are as follows:</p>



     <p>FOR THE PURPOSES OF:</p>



     <p>The subject matter and duration of the Processing</p>



     <p>• &nbsp; Business&nbsp; Software developments</p>



     <p>• &nbsp; Data protection services&nbsp;</p>



     <p>• &nbsp; Data hosting services</p>



     <p>• &nbsp; Other IT services</p>



     <p>• &nbsp; Domain Registration</p>



     <p>The personal data will be processed for the duration of the service(s) provided or for the time taken.</p>



     <p>The nature and purpose of the Processing</p>



     <p>• &nbsp; Processing of personal data provided and passing to third party organisation if required by Law or for ordered services.</p>



     <p>• &nbsp; Processing of personal data provided for due diligence checks, where applicable</p>



     <p>•&nbsp; &nbsp; Processing of personal data for the purpose of registering domain names, hosting and dedicated servers if ordered by the customer</p>



     <p>PERSONAL DATA PROCESSED</p>



     <p>The type of Personal Data being Processed</p>



     <p>• &nbsp; Full Name</p>



     <p>• &nbsp; Addresses (current and previous residential addresses)</p>



     <p>• &nbsp; Date of Birth</p>



     <p>• &nbsp; Place of Birth</p>



     <p>• &nbsp; Telephone Number</p>



     <p>• &nbsp; Email Address</p>



     <p>• &nbsp; Mother’s maiden name</p>



     <p>• &nbsp; Father’s forename</p>



     <p>• &nbsp; Passport number</p>



     <p>• &nbsp; NI number</p>



     <p>The categories of Data Subjects</p>



     <p>• &nbsp; Data subjects to be appointed to companies as Director, Shareholder, Secretary, Beneficial Owner, Person of Significant Control</p>



     <p>• &nbsp; Customers</p>



     <p>• &nbsp; Domain registrants</p>



     <p>We shall, in relation to any Personal Data processed in the course of providing services to you:</p>



     <p>• &nbsp; process Personal Data only on your instructions (unless we have a legal obligation to process the Personal Data which, where permissible, we will have notified to you before performing the processing required);</p>



     <p>• &nbsp; have in place reasonable and proportionate technical and organisational measures to protect against unauthorised or unlawful processing of Personal Data and against accidental loss or destruction of, or damage to, Personal Data;</p>



     <p>•&nbsp; &nbsp; ensure that all personnel who have access to and/or process Personal Data are obliged to keep the Personal Data confidential;</p>



     <p>• &nbsp; not appoint a third-party processor without your prior consent, save for the following third-party data processors for which your acceptance of these terms and conditions shall constitute consent:</p>



     <p>• &nbsp; Tucows Inc.,&nbsp; Enom LLC and Domain.Com Inc(domain registrants and website hosting suppliers)</p>



     <p>• &nbsp; not transfer any Personal Data outside of the European Economic Area without your prior consent;</p>



     <p>• &nbsp; assist you, at your cost, in responding to any request from a Data Subject and in ensuring compliance with your obligations under the Data Protection Legislation with respect to security, breach notifications and impact assessments;</p>



     <p>• &nbsp; notify you without undue delay on becoming aware of a Personal Data breach;</p>



     <p>• &nbsp; on your written direction, delete or return Personal Data and copies thereof to you unless subject to a legal obligation to store the Personal Data; and</p>



     <p>• &nbsp; submit to audits requested by you.</p>



     <p>9. COOKIES</p>



     <p>•&nbsp; &nbsp; Cookies are small data files that are stored locally on your computer and which enable us to tailor your experience in using the Website. Accounts and password information may be stored in cookies. The Website uses cookies, and you are deemed to consent to the use of cookies by using the Website. You can turn off the use of cookies in your Web browser. Please consult the help files for your browser for more information on how to do this. Full details of the cookies we use are contained within our Cookie Policy.</p>



     <p>10. TERMINATING THE USE OF THE WEBSITE</p>



     <p>•&nbsp; &nbsp; We may withdraw or suspend your right to access or use the Website at any time, without prior notice and without providing any reason.</p>



     <p>11. WAIVER</p>



     <p>•&nbsp; &nbsp; No waiver by us (whether express or implied) in enforcing any of our rights shall prejudice our right to enforce such rights in the future</p>



     <p>12. GENERAL</p>



     <p>If any provision of these General Terms and Conditions is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and the validity and enforceability of the remaining provisions shall not be affected</p>



     <p>•&nbsp; &nbsp; In the event of there being any conflict between these General Terms and Conditions and the Specific Terms and Conditions that apply specifically to the purchase of certain goods or services through the Website, the Specific Terms and Conditions shall prevail.</p>



     <p>•&nbsp; &nbsp; No person who is not a direct party to any agreement covered by these General Terms and conditions shall have any right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Agreement.</p>



     <p>•&nbsp; &nbsp; Where you are a customer, you have the right to cancel the contract for the provision of goods or services, by notice in writing, at any time before seven working days have passed from the day after the contract was made. If, however, we have started to perform our side of the contract before you exercise your right to cancel, then the right to cancel is lost.</p>



     <p>13. NOTICES</p>



     <p>•&nbsp; &nbsp; This clause applies where these General Terms and Conditions or the Specific Terms and Conditions provide expressly or by implication for the service of notices.</p>



     <p>•&nbsp; &nbsp; Any notice required to be given under our Agreement with you or in connection with the matters contemplated by it shall, except where otherwise specifically provided, be in writing in the English language.</p>



     <p>•&nbsp; &nbsp; Any such notice shall be addressed to the usual business address of the other party and may be:</p>



     <p>•&nbsp; &nbsp; Personally delivered, in which case it shall be deemed to have been given upon delivery at the relevant address if it is delivered not later than 17.00 hours on a Business Day, or, if it is delivered later than 17.00 hours on a Business Day or at any time on a day which is not a Business Day, at 08.00 hours on the next Business Day; or</p>



     <p>•&nbsp; &nbsp; If within the United Kingdom, sent by first class pre-paid post, in which case it shall be deemed to have been given two Business Days after the date of posting; or</p>



     <p>•&nbsp; &nbsp; If from or to any place outside the United Kingdom, sent by pre-paid airmail, or by air courier in which case it shall be deemed to have been given seven Business Days after the date of posting in the case of airmail or two Business Days after delivery to the courier, in the case of air courier;</p>



     <p>•&nbsp; &nbsp; Sent by facsimile, in which case it shall be deemed to have been given when despatched, subject to confirmation of uninterrupted transmission by a transmission report provided that any notice despatched by facsimile after 17.00 hours on any Business Day or at any time on a day which is not a Business Day shall be deemed to have been given at 08.00 on the next Business Day; or</p>



     <p>•&nbsp; &nbsp; Sent by electronic mail, in which case, it shall be deemed to be given when received but subject to the same provisions regarding receipt after 17.00 hours as apply to notices sent by Facsimile.</p>



     <p>14. GOVERNING LAW AND JURISDICTION</p>



     <p>• &nbsp; Your use of the Website and the purchase of any products or services from it are governed in accordance with the laws of England and Wales.</p>



     <p>• &nbsp; The English courts shall have exclusive jurisdiction over any dispute or difference whatsoever arising out of or in connection with your use of the Website or the purchase of any products or services from it.</p>



     <p>Specific Terms and Conditions</p>



     <p>These Specific Terms and Conditions are applicable to transactions made through the website you are browsing when you clicked on a link to these General Terms and Conditions Definitions</p>



     <p>1. INFORMATION ABOUT US</p>



     <p>•&nbsp; &nbsp; This website is operated FoodNear Company (“MA”, “we”, or “us”).</p>



     <p>2. LIMITATION OF LIABILITY</p>



     <p>•&nbsp; &nbsp; We do not accept any liability for any errors or omissions in the order information you submit through our Website or via our offline application forms.</p>



     <p>•&nbsp; &nbsp; We do not warrant that the use of the application or product name will not conflict with the rights of currently operating businesses, and in particular we do not warrant that the use of the name may not give rise to actions for passing off, or for infringement of any other proprietary or legal right. We have not investigated and cannot investigate the possibility of the existence of conflicting rights and the Customer accepts sole responsibility for meeting all and any claims of any kind whatsoever arising out of the use of the company name and agrees to indemnify us in respect of any costs, expenses or damages it suffers or for which it is held liable as a result of any such claims.</p>



     <p>•&nbsp; &nbsp; For Informaition Search Reports, we recommend that you consult with an appropriately qualified professional (solicitor, accountant or other advisor) for advice and analysis in relation to the contents of any of our reports as to the appropriateness of any decisions you may take or refrain from taking based in whole or in part upon the data contained within the reports ordered.</p>



     <p>•&nbsp; &nbsp; Our liability in respect of all claims arising out of or in connection with your use of this website shall not exceed an amount equal to the sums payable by you to us.</p>



     <p>3. ORDERING</p>



     <p>•&nbsp; &nbsp; All orders that you place through this Website or via our offline application forms are deemed to be an offer by you to purchase the products or services that we supply subject to these Terms and are subject to acceptance of the order by ourselves. We may choose to reject any order without providing a reason beforehand.</p>



     <p>•&nbsp; &nbsp; You are presented with a range of choices during the ordering process. It is your responsibility to ensure that you read and fully understand these choices before you proceed with any purchase. In the event that you have any queries regarding any aspect of your order or our products and/or services we strongly recommend that you contact us during our usual UK office hours prior to proceeding with a purchase. Please note that although we endeavour to provide a prompt response to your enquiry, we cannot guarantee to do so in every instance. It therefore remains your responsibility to elicit further information from us regarding the product you intend to order before the order is placed.</p>



     <p>•&nbsp; &nbsp; As a&nbsp; IT Service Provider, we have an ongoing responsibility to perform regular checks on any customer (and its officers and beneficial owners) to which we provide ongoing services. Your acceptance of these Terms and Conditions authorises us to open any mail delivered to our address in respect of any company you have formed through this website or have engaged us to provide ongoing services to. In the event that any of our checks uncover information or activities that are illegal, unethical or otherwise outside of our risk appetite then we reserve the right to terminate services without notice and without refund</p>



     <p>•&nbsp; &nbsp; Further to iii) above, we may require you to provide evidence of your identity and address in the form of original certified documents to satisfy our internal Anti-Money Laundering procedures. Failure to comply with any request for such documents within a reasonable time frame (specified at the time of any request) may result in the termination of services. No refund shall be given for the termination of services resulting from your failure to satisfactorily comply with our Anti-Money Laundering procedures.</p>



     <p>•&nbsp; &nbsp; We reserve the right at our sole discretion to deny users access to our website or any part of our website without notice and to decline to provide the service to any user that is in breach of these Terms and Conditions.</p>



     <p>4. DOMAIN NAMES AND WEB HOSTING</p>



     <p>•&nbsp; &nbsp; Domain, email and web hosting services are renewable on an annual basis. You will receive an email notification in advance of the expiry of your services with a link to renew. If you do not renew your services then they will automatically be cancelled. This may result in you losing access to your domain, email and / or website.</p>



     <p>•&nbsp; &nbsp; Our email reminders are a courtesy only and it is your responsibility to ensure that your annual services are paid up to date in order to keep them active.</p>



     <p>5. REFUND POLICY</p>



     <p>•&nbsp; &nbsp; In the event that you make a purchase of any services but then change your mind prior to the order will be proceeded by our staff we will refund all monies paid, less a £100.00 administration charge. This charge covers our merchant charges (both on the purchase and the refund) and other incidental expenses. Refunds cannot be given once the order has been submitted by client. Other products will be refunded (subject to the £100.00 administration charge) provided we are notified within two weeks of the purchase date. No refund will be given if services are terminated by us because of any breach of our terms and conditions.</p>



     <p>Terms and Conditions for the supply of other business services</p>



     <p>1. DEFINITIONS</p>



     <p>In these terms and conditions, the following words shall have the following meanings:</p>



     <p>"The Company" means FoodNear Company</p>



     <p>"Customer" means the person, company, partnership or other organisation placing an Order either on their own behalf as agent.</p>



     <p>"Order" means an instruction to carry out a Service (as defined below).</p>



     <p>"Regulated Search Report" means a report compiled by the Company after a search of local authority records.</p>



     <p>"Report" means a search report prepared by the Company or a Supplier as part of the Service.</p>



     <p>"Search Code" means the Search Code of Practice for Compilers and Retailers published by the&nbsp; Search Organisations.</p>



     <p>"Service" means the supply of search and indemnity services by the Company to the Customer including but not limited to Regulated Search Reports and other services from time to time and includes services ordered from a Supplier on behalf of the Customer and the dissemination of the information subsequently provided by the Supplier.</p>



     <p>"Supplier" means any organisation or third party who provides data or information of any form to the Company for the purposes of providing the Service.</p>



     <p>"Terms" means these terms and conditions of business.</p>



     <p>"we" or "us" or "our" or "ourselves" refers to the Company</p>



     <p>"you" or "your" or "yourself" refers to the Customer</p>



     <p>2. AGREEMENT</p>



     <p>•&nbsp; &nbsp; We agree to supply the Service to you subject to these Terms and you indicate your acceptance of these Terms when placing an order for the Service or when relying on the information in the Service.</p>



     <p>3. THE SERVICE</p>



     <p>We will provide the Service to you on the basis that you acknowledge and agree to the following:</p>



     <p>•&nbsp; &nbsp; The information in the Service reflects that available to us on the date the Service is produced.</p>



     <p>•&nbsp; &nbsp; The information contained in the Service can change on a regular basis and we cannot be responsible to you or to any person relying on the Service for any change in the information after the date on which the Service is produced and sent to you or for any inaccuracies, omissions or errors on a public register.</p>



     <p>•&nbsp; &nbsp; In providing the Services we will comply with the Search Code and will produce Regulated Search Reports with reasonable care and skill.</p>



     <p>4. LIABILITY AND INSURANCE</p>



     <p>•&nbsp; &nbsp; We shall not be liable for any acts or omissions of any party for whom we are not responsible.</p>



     <p>•&nbsp; &nbsp; We accept liability for death or personal injury arising from our negligence.</p>



     <p>•&nbsp; &nbsp; We accept liability (subject to these terms) for and have insurance in place to protect you or any person relying on the Service against negligence or errors by us or the relevant Supplier and with regard to information to be included in the Service.</p>



     <p>•&nbsp; &nbsp; The limit under our Professional Indemnity is £1000&nbsp; You acknowledge that a service from a Supplier may carry a different limit of indemnity.</p>



     <p>•&nbsp; &nbsp; Any claim relating to data or information obtained from a Supplier shall in the first instance be made against the Supplier, with such assistance from us as may reasonably be required, and only if such a claim cannot be made against the Supplier will you make a claim against us and in no circumstances shall the amount of such claim exceed the indemnity limit of the Supplier</p>



     <p>5. PRICE AND PAYMENT</p>



     <p>•&nbsp; &nbsp; The price payable for the Service is inclusive all&nbsp; taxes, unless otherwise stated.</p>



     <p>•&nbsp; &nbsp; Unless you have an account with us for payment of the Service, we must receive payment for the Service in full before the Service is produced.</p>



     <p>6. CONFIDENTIALITY</p>



     <p>•&nbsp; &nbsp; All instructions and information received by us shall be dealt with in strictest confidence.</p>



     <p>7. COPYRIGHT</p>



     <p>•&nbsp; &nbsp; Without prejudice to the licence for you and all others relying on the Service to use and take the benefit of the Service, the copyright and intellectual property rights in a Report shall remain our property save where such copyright and intellectual property rights are vested in any Supplier or other party.</p>



     <p>•&nbsp; &nbsp; You agree to respect and not to alter any trademark, copyright notice or trading name on any Service supplied by us.</p>



     <p>•&nbsp; &nbsp; You agree to indemnify us against any costs, claims and damage suffered by us as a result of any breach by you of the copyright terms in paragraphs 7 i) and 7 ii).</p>



     <p>8. RETENTION OF TITLE</p>



     <p>•&nbsp; &nbsp; Title to any Service supplied shall remain vested in us and shall not pass to you until the purchase price for the Service has been paid in full and received by us.</p>



     <p>9. DATA PROTECTION</p>



     <p>•&nbsp; &nbsp; We take our obligations of confidentiality and the protection of personal data very seriously. We will not, therefore, sell or make personal data available to any third party without your prior consent.</p>



     <p>TRANSACTIONS FROM CUSTOMERS PLACING AN ORDER ON THEIR OWN BEHALF</p>



     <p>•&nbsp; &nbsp; Where you request the Service for your personal benefit and not as a professional intermediary on behalf of others:</p>



     <p>•&nbsp; &nbsp; For the purposes of applicable data protection legislation, we will process any personal data you have provided in accordance with the Privacy Notice available on our website or on request from us. If you have any queries about the manner in which personal data will be processed by us or your rights in relation to such processing you should contact us via email or telephone, the details for both of which are accessible on this website.</p>



     <p>•&nbsp; &nbsp; You agree that, if you have provided to us personal data relating to a third party (1) you have in place all necessary appropriate consents and notices to enable lawful transfer of such personal data to us and (2) that you have brought to the attention of any such third party the Privacy Notice available on our website or otherwise provided a copy of it to the third party. You agree to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>TRANSACTIONS WITH PROFESSIONAL INTERMEDIARIES</p>



     <p>•&nbsp; &nbsp; Where you request the Service on behalf of others:</p>



     <p>• &nbsp; It is agreed that both parties will comply with the Data Protection Legislation and that this clause is in addition to, and does not replace, a party's obligations under the Data Protection Legislation. Data Protection Legislation means: (i) unless and until the GDPR is no longer directly applicable in the UK, the General Data Protection Regulation ((EU) 2016/679) and any national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK and then (ii) any successor legislation to the GDPR or the Data Protection Act 1998.</p>



     <p>• &nbsp; The parties acknowledge that for the purposes of the Data Protection Legislation, you are the data controller and we are the data processor.</p>



     <p>• &nbsp; You will ensure that you have all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to us for the duration and purposes of this agreement. You agree that you will provide us with such evidence as we require in relation to your compliance with these requirements and, further, to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>Details of the personal data that we process as a Data Processor are as follows:</p>



     <p>•&nbsp; &nbsp; The subject matter – The provision of the Service to the Customer</p>



     <p>•&nbsp; &nbsp; The duration of the Processing – Personal data is processed for the duration of the Service and retained subject to clause 9 iii) e. H below.</p>



     <p>•&nbsp; &nbsp; The nature and purpose of the Processing – We process personal data for the purpose of providing the Service to the Customer</p>



     <p>•&nbsp; &nbsp; The type of personal data being processed – Name and address</p>



     <p>•&nbsp; &nbsp; The categories of Data Subjects – Customers</p>



     <p>We shall, in relation to any personal data processed in the course of providing a service to you</p>



     <p>•&nbsp; &nbsp; process personal data only on your instructions (unless we have a legal obligation to process the personal data which, where permissible, we will have notified to you before performing the processing required);</p>



     <p>•&nbsp; &nbsp; have in place reasonable and proportionate technical and organisational measures to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data;</p>



     <p>•&nbsp; &nbsp; ensure that all personnel who have access to and/or process personal data are obliged to keep the personal data confidential;</p>



     <p>•&nbsp; &nbsp; not appoint a third-party processor without your consent, which shall be deemed to have been given if you have ordered a Service provided by a Supplier</p>



     <p>•&nbsp; &nbsp; not transfer any personal data outside of the European Economic Area without your prior written consent;</p>



     <p>•&nbsp; &nbsp; assist you, at your cost, in responding to any request from a Data Subject and in ensuring compliance with your obligations under the Data Protection Legislation with respect to security, breach notifications and impact assessments;</p>



     <p>•&nbsp; &nbsp; notify you without undue delay on becoming aware of a personal data breach;</p>



     <p>•&nbsp; &nbsp; on your written direction, delete or return personal data and copies thereof to you unless subject to a legal obligation to store the personal data; and</p>



     <p>•&nbsp; &nbsp; submit to audits requested by you.</p>



     <p>10. COMPLAINTS PROCEDURE</p>



     <p>•&nbsp; &nbsp; We&nbsp; are registered&nbsp; subscriber to the Search Code. A key commitment under the Code is that firms will handle any complaints both speedily and fairly. If you want to make a complaint, we will:</p>



     <p>• &nbsp; Acknowledge it within 5 working days of receipt.</p>



     <p>•&nbsp; &nbsp; Normally deal with it fully and provide a final response, in writing, within 20 working days of receipt.</p>



     <p>•&nbsp; &nbsp; Keep you informed by letter, telephone or e-mail, as you prefer, if we need more time.</p>



     <p>•&nbsp; &nbsp; Provide a final response, in writing, at the latest within 40 working days of receipt.</p>



     <p>• &nbsp; Liaise, at your request, with anyone acting formally on your behalf.</p>



     <p>11. GENERAL</p>



     <p>•&nbsp; &nbsp; If any term is held to be invalid or unenforceable, that provision or part of that provision shall be taken to be removed from these Terms and the remaining terms will continue in full force and effect.</p>



     <p>•&nbsp; &nbsp; These Terms shall be governed by English law and shall be subject to the jurisdiction of the English Courts.</p>



     <p>1. DEFINITIONS</p>



     <p>The following definitions apply</p>



     <p>•&nbsp; &nbsp; "Customer" shall mean the person (including corporate entities or any other legal person) that we are contracting with for the sale of goods or services and in the case of ongoing services, the person receiving those services.</p>



     <p>•&nbsp; &nbsp; "General Terms and Conditions" means these terms and conditions</p>



     <p>•&nbsp; &nbsp; "Specific Terms and Conditions" means the Specific terms and conditions tailored to cover any product or service that you may purchase through the Website, and which take priority over these General Terms and Conditions to the extent of any conflict between them.</p>



     <p>•&nbsp; &nbsp; "Web site" "Website" or "Site" means the website you are browsing when you clicked on a link to these General Terms and Conditions, including all subsidiary pages.</p>



     <p>•&nbsp; &nbsp; "we" or "us" or "our" or "ourselves" refers to FoodNear Company</p>



     <p>2. INFORMATION CONTAINED ON THE WEBSITE</p>



     <p>•&nbsp; &nbsp; Whilst we take all reasonable care to ensure that the information contained on the Website is accurate and up to date, we make no representations, warranties or undertakings about any of the information content or materials provided on the Website (including, without limitation, any as to quality, accuracy, completeness or reliability).</p>



     <p>•&nbsp; &nbsp; All material on the Website is provided for information purposes only and does not constitute legal, accounting or other professional advice, and it must therefore not be relied upon as such. You should arrange your own advice from a qualified party before acting in reliance on any of the information, or purchasing any of the products or services, available on or from the Website.</p>



     <p>3. UPDATES AND CHANGES</p>



     <p>•&nbsp; &nbsp; The Website is being updated and improved on an ongoing basis. We reserve the right to change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm that we shall not be liable to you for any such change or removal; and</p>



     <p>•&nbsp; &nbsp; Changes to these General Terms and Conditions or to the Specific Terms and Conditions may be made at any time and your use of the Website, or the purchase of products or services, are subject to any such changes. You agree to check to see if any changes have been made to the General or the relevant Specific terms each time you visit the Website or purchase products or services from it.</p>



     <p>4. EXCLUSION OF LIABILITY TO YOU FROM THE USE OF THE WEBSITE</p>



     <p>•&nbsp; &nbsp; The Website is provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>



     <p>•&nbsp; &nbsp; Any and all liability to you that may arise from your access to and use of the Website, whether due to negligence, breach of duty or otherwise, is excluded to the maximum extent permitted by law.</p>



     <p>•&nbsp; &nbsp; No warranty is given that the functionality of the Website will be uninterrupted or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or destructive.</p>



     <p>•&nbsp; &nbsp; We are not responsible for the content of other Websites that link to the Website, nor are we responsible for the content of any Website to which links are provided from the Website. Links to other sites are provided purely for your convenience and do not imply that we approve of those sites.</p>



     <p>•&nbsp; &nbsp; Nothing in these General Terms and Conditions shall be construed so as to exclude or limit the liability of ourselves for death or personal injury as a result of our negligence or that of its employees or agents.</p>



     <p>5. COPYRIGHT AND TRADE MARKS (INTELLECTUAL PROPERTY)</p>



     <p>•&nbsp; &nbsp; The copyright in all materials on the Website, including their design, layout, text, graphics, photographs and the source code and software belong to their respective owners. Trade marks (whether registered or not) company names and the like are the property of their respective owners.</p>



     <p>•&nbsp; &nbsp; You are licensed to view and temporarily store Website pages and their content in your browser’s temporary cache, and also to print out for reference a single copy for non-commercial purposes and off-line review. You may not sell or re-sell anything available from the Website, save to the extent expressly permitted pursuant to any product or service purchased by you from the Website where such permission is either expressly given or is a necessary attribute of the product or service concerned.</p>



     <p>6. FORCE MAJEURE – SUPPLY OF GOODS OR SERVICES ORDERED THROUGH THE WEBSITE</p>



     <p>•&nbsp; &nbsp; In connection with the supply of any goods or services ordered by you through the Website, we shall not be liable for any delay or failure to perform any of our obligations if the delay or failure results from events or circumstances outside our reasonable control, including but not limited to acts of God, strikes, lock outs, accidents, war, fire or failure of any communications, telecommunications or computer system, and we shall be entitled to a reasonable extension of our obligations to you (to the extent we owe any such obligations) should a force Majeure event occur.</p>



     <p>•&nbsp; &nbsp; If a Force Majeure event to which this clause applies shall occur, we agree to notify you as soon as practicable. If the Force Majeure event continues for more than 14 days, either party shall have the right to cancel the agreement and where services have been paid for in advance but have not been rendered, you will be entitled to a refund from the date of cancellation for all such services.</p>



     <p>7. USER NAME AND PASSWORD</p>



     <p>•&nbsp; &nbsp; The website may provide the facility to register in order to gain enhanced access privileges or in order to purchase products or services. If you register, it is your responsibility to maintain the confidentiality of your password. On no account should you disclose your password to anyone else. You agree to indemnify and hold us harmless for any loss or damage we may incur resulting from breach of this clause.</p>



     <p>8. DATA PROTECTION</p>



     <p>• We take our obligations of confidentiality and the protection of personal data very seriously. We will not, therefore, sell or make personal data available to any third party without your prior consent.</p>



     <p>TRANSACTIONS WITH CUSTOMERS PLACING AN ORDER ON THEIR OWN BEHALF</p>



     <p>•&nbsp; &nbsp; Where you request the our services for your personal benefit and not as a professional intermediary on behalf of others:</p>



     <p>•&nbsp; &nbsp; For the purposes of applicable data protection legislation, we will process any personal data you have provided to us in accordance with our Privacy Policy available on our website or on request from us. If you have any queries about the manner in which personal data will be processed by us or your rights in relation to such processing you should contact us via our contact form or on our telephone number, both of which are accessible on this website.</p>



     <p>• &nbsp; &nbsp; You agree that, if you have provided to us personal data relating to a third party (1) you have in place all necessary appropriate consents and notices to enable lawful transfer of such personal data to us and (2) that you have brought to the attention of any such third party the Privacy Policy available on our website or otherwise provided a copy of it to the third party. You agree to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>TRANSACTIONS WITH PROFESSIONAL INTERMEDIARIES</p>



     <p>•&nbsp; &nbsp; Where you are request our services on behalf of others:</p>



     <p>•&nbsp; &nbsp; It is agreed that both parties will comply with the Data Protection Legislation and that this clause is in addition to, and does not replace, a party's obligations under the Data Protection Legislation. Data Protection Legislation means: (i) unless and until the GDPR is no longer directly applicable in the UK, the General Data Protection Regulation ((EU) 2016/679) and any national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK and then (ii) any successor legislation to the GDPR or the Data Protection Act 1998.</p>



     <p>•&nbsp; &nbsp; The parties acknowledge that for the purposes of the Data Protection Legislation, you are the data controller and we are the data processor.</p>



     <p>•&nbsp; &nbsp; Notwithstanding b. above, the parties acknowledge that for the purposes of the Data Protection Legislation, where ongoing services are being provided by us to your customer, that we are each data controllers.</p>



     <p>•&nbsp; &nbsp; You will ensure that you have all necessary appropriate consents and notices in place to enable lawful transfer of the Personal Data to us for the duration and purposes of this agreement. You agree to provide us with such evidence as we require in relation to your compliance with these requirements and, further, to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>•&nbsp; &nbsp; Details of the Personal Data that we process as a Data Processor are as follows:</p>



     <p>FOR THE PURPOSES OF:</p>



     <p>The subject matter and duration of the Processing</p>



     <p>• &nbsp; Business&nbsp; Software developments</p>



     <p>• &nbsp; Data protection services&nbsp;</p>



     <p>• &nbsp; Data hosting services</p>



     <p>• &nbsp; Other IT services</p>



     <p>• &nbsp; Domain Registration</p>



     <p>The personal data will be processed for the duration of the service(s) provided or for the time taken.</p>



     <p>The nature and purpose of the Processing</p>



     <p>• &nbsp; Processing of personal data provided and passing to third party organisation if required by Law or for ordered services.</p>



     <p>• &nbsp; Processing of personal data provided for due diligence checks, where applicable</p>



     <p>•&nbsp; &nbsp; Processing of personal data for the purpose of registering domain names, hosting and dedicated servers if ordered by the customer</p>



     <p>PERSONAL DATA PROCESSED</p>



     <p>The type of Personal Data being Processed</p>



     <p>• &nbsp; Full Name</p>



     <p>• &nbsp; Addresses (current and previous residential addresses)</p>



     <p>• &nbsp; Date of Birth</p>



     <p>• &nbsp; Place of Birth</p>



     <p>• &nbsp; Telephone Number</p>



     <p>• &nbsp; Email Address</p>



     <p>• &nbsp; Mother’s maiden name</p>



     <p>• &nbsp; Father’s forename</p>



     <p>• &nbsp; Passport number</p>



     <p>• &nbsp; NI number</p>



     <p>The categories of Data Subjects</p>



     <p>• &nbsp; Data subjects to be appointed to companies as Director, Shareholder, Secretary, Beneficial Owner, Person of Significant Control</p>



     <p>• &nbsp; Customers</p>



     <p>• &nbsp; Domain registrants</p>



     <p>We shall, in relation to any Personal Data processed in the course of providing services to you:</p>



     <p>• &nbsp; process Personal Data only on your instructions (unless we have a legal obligation to process the Personal Data which, where permissible, we will have notified to you before performing the processing required);</p>



     <p>• &nbsp; have in place reasonable and proportionate technical and organisational measures to protect against unauthorised or unlawful processing of Personal Data and against accidental loss or destruction of, or damage to, Personal Data;</p>



     <p>•&nbsp; &nbsp; ensure that all personnel who have access to and/or process Personal Data are obliged to keep the Personal Data confidential;</p>



     <p>• &nbsp; not appoint a third-party processor without your prior consent, save for the following third-party data processors for which your acceptance of these terms and conditions shall constitute consent:</p>



     <p>• &nbsp; Tucows Inc.,&nbsp; Enom LLC and Domain.Com Inc(domain registrants and website hosting suppliers)</p>



     <p>• &nbsp; not transfer any Personal Data outside of the European Economic Area without your prior consent;</p>



     <p>• &nbsp; assist you, at your cost, in responding to any request from a Data Subject and in ensuring compliance with your obligations under the Data Protection Legislation with respect to security, breach notifications and impact assessments;</p>



     <p>• &nbsp; notify you without undue delay on becoming aware of a Personal Data breach;</p>



     <p>• &nbsp; on your written direction, delete or return Personal Data and copies thereof to you unless subject to a legal obligation to store the Personal Data; and</p>



     <p>• &nbsp; submit to audits requested by you.</p>



     <p>9. COOKIES</p>



     <p>•&nbsp; &nbsp; Cookies are small data files that are stored locally on your computer and which enable us to tailor your experience in using the Website. Accounts and password information may be stored in cookies. The Website uses cookies, and you are deemed to consent to the use of cookies by using the Website. You can turn off the use of cookies in your Web browser. Please consult the help files for your browser for more information on how to do this. Full details of the cookies we use are contained within our Cookie Policy.</p>



     <p>10. TERMINATING THE USE OF THE WEBSITE</p>



     <p>•&nbsp; &nbsp; We may withdraw or suspend your right to access or use the Website at any time, without prior notice and without providing any reason.</p>



     <p>11. WAIVER</p>



     <p>•&nbsp; &nbsp; No waiver by us (whether express or implied) in enforcing any of our rights shall prejudice our right to enforce such rights in the future</p>



     <p>12. GENERAL</p>



     <p>If any provision of these General Terms and Conditions is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and the validity and enforceability of the remaining provisions shall not be affected</p>



     <p>•&nbsp; &nbsp; In the event of there being any conflict between these General Terms and Conditions and the Specific Terms and Conditions that apply specifically to the purchase of certain goods or services through the Website, the Specific Terms and Conditions shall prevail.</p>



     <p>•&nbsp; &nbsp; No person who is not a direct party to any agreement covered by these General Terms and conditions shall have any right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Agreement.</p>



     <p>•&nbsp; &nbsp; Where you are a customer, you have the right to cancel the contract for the provision of goods or services, by notice in writing, at any time before seven working days have passed from the day after the contract was made. If, however, we have started to perform our side of the contract before you exercise your right to cancel, then the right to cancel is lost.</p>



     <p>13. NOTICES</p>



     <p>•&nbsp; &nbsp; This clause applies where these General Terms and Conditions or the Specific Terms and Conditions provide expressly or by implication for the service of notices.</p>



     <p>•&nbsp; &nbsp; Any notice required to be given under our Agreement with you or in connection with the matters contemplated by it shall, except where otherwise specifically provided, be in writing in the English language.</p>



     <p>•&nbsp; &nbsp; Any such notice shall be addressed to the usual business address of the other party and may be:</p>



     <p>•&nbsp; &nbsp; Personally delivered, in which case it shall be deemed to have been given upon delivery at the relevant address if it is delivered not later than 17.00 hours on a Business Day, or, if it is delivered later than 17.00 hours on a Business Day or at any time on a day which is not a Business Day, at 08.00 hours on the next Business Day; or</p>



     <p>•&nbsp; &nbsp; If within the United Kingdom, sent by first class pre-paid post, in which case it shall be deemed to have been given two Business Days after the date of posting; or</p>



     <p>•&nbsp; &nbsp; If from or to any place outside the United Kingdom, sent by pre-paid airmail, or by air courier in which case it shall be deemed to have been given seven Business Days after the date of posting in the case of airmail or two Business Days after delivery to the courier, in the case of air courier;</p>



     <p>•&nbsp; &nbsp; Sent by facsimile, in which case it shall be deemed to have been given when despatched, subject to confirmation of uninterrupted transmission by a transmission report provided that any notice despatched by facsimile after 17.00 hours on any Business Day or at any time on a day which is not a Business Day shall be deemed to have been given at 08.00 on the next Business Day; or</p>



     <p>•&nbsp; &nbsp; Sent by electronic mail, in which case, it shall be deemed to be given when received but subject to the same provisions regarding receipt after 17.00 hours as apply to notices sent by Facsimile.</p>



     <p>14. GOVERNING LAW AND JURISDICTION</p>



     <p>• &nbsp; Your use of the Website and the purchase of any products or services from it are governed in accordance with the laws of England and Wales.</p>



     <p>• &nbsp; The English courts shall have exclusive jurisdiction over any dispute or difference whatsoever arising out of or in connection with your use of the Website or the purchase of any products or services from it.</p>



     <p>Specific Terms and Conditions</p>



     <p>These Specific Terms and Conditions are applicable to transactions made through the website you are browsing when you clicked on a link to these General Terms and Conditions Definitions</p>



     <p>1. INFORMATION ABOUT US</p>



     <p>•&nbsp; &nbsp; This website is operated FoodNear Company (“MA”, “we”, or “us”).</p>



     <p>2. LIMITATION OF LIABILITY</p>



     <p>•&nbsp; &nbsp; We do not accept any liability for any errors or omissions in the order information you submit through our Website or via our offline application forms.</p>



     <p>•&nbsp; &nbsp; We do not warrant that the use of the application or product name will not conflict with the rights of currently operating businesses, and in particular we do not warrant that the use of the name may not give rise to actions for passing off, or for infringement of any other proprietary or legal right. We have not investigated and cannot investigate the possibility of the existence of conflicting rights and the Customer accepts sole responsibility for meeting all and any claims of any kind whatsoever arising out of the use of the company name and agrees to indemnify us in respect of any costs, expenses or damages it suffers or for which it is held liable as a result of any such claims.</p>



     <p>•&nbsp; &nbsp; For Informaition Search Reports, we recommend that you consult with an appropriately qualified professional (solicitor, accountant or other advisor) for advice and analysis in relation to the contents of any of our reports as to the appropriateness of any decisions you may take or refrain from taking based in whole or in part upon the data contained within the reports ordered.</p>



     <p>•&nbsp; &nbsp; Our liability in respect of all claims arising out of or in connection with your use of this website shall not exceed an amount equal to the sums payable by you to us.</p>



     <p>3. ORDERING</p>



     <p>•&nbsp; &nbsp; All orders that you place through this Website or via our offline application forms are deemed to be an offer by you to purchase the products or services that we supply subject to these Terms and are subject to acceptance of the order by ourselves. We may choose to reject any order without providing a reason beforehand.</p>



     <p>•&nbsp; &nbsp; You are presented with a range of choices during the ordering process. It is your responsibility to ensure that you read and fully understand these choices before you proceed with any purchase. In the event that you have any queries regarding any aspect of your order or our products and/or services we strongly recommend that you contact us during our usual UK office hours prior to proceeding with a purchase. Please note that although we endeavour to provide a prompt response to your enquiry, we cannot guarantee to do so in every instance. It therefore remains your responsibility to elicit further information from us regarding the product you intend to order before the order is placed.</p>



     <p>•&nbsp; &nbsp; As a&nbsp; IT Service Provider, we have an ongoing responsibility to perform regular checks on any customer (and its officers and beneficial owners) to which we provide ongoing services. Your acceptance of these Terms and Conditions authorises us to open any mail delivered to our address in respect of any company you have formed through this website or have engaged us to provide ongoing services to. In the event that any of our checks uncover information or activities that are illegal, unethical or otherwise outside of our risk appetite then we reserve the right to terminate services without notice and without refund</p>



     <p>•&nbsp; &nbsp; Further to iii) above, we may require you to provide evidence of your identity and address in the form of original certified documents to satisfy our internal Anti-Money Laundering procedures. Failure to comply with any request for such documents within a reasonable time frame (specified at the time of any request) may result in the termination of services. No refund shall be given for the termination of services resulting from your failure to satisfactorily comply with our Anti-Money Laundering procedures.</p>



     <p>•&nbsp; &nbsp; We reserve the right at our sole discretion to deny users access to our website or any part of our website without notice and to decline to provide the service to any user that is in breach of these Terms and Conditions.</p>



     <p>4. DOMAIN NAMES AND WEB HOSTING</p>



     <p>•&nbsp; &nbsp; Domain, email and web hosting services are renewable on an annual basis. You will receive an email notification in advance of the expiry of your services with a link to renew. If you do not renew your services then they will automatically be cancelled. This may result in you losing access to your domain, email and / or website.</p>



     <p>•&nbsp; &nbsp; Our email reminders are a courtesy only and it is your responsibility to ensure that your annual services are paid up to date in order to keep them active.</p>



     <p>5. REFUND POLICY</p>



     <p>•&nbsp; &nbsp; In the event that you make a purchase of any services but then change your mind prior to the order will be proceeded by our staff we will refund all monies paid, less a £100.00 administration charge. This charge covers our merchant charges (both on the purchase and the refund) and other incidental expenses. Refunds cannot be given once the order has been submitted by client. Other products will be refunded (subject to the £100.00 administration charge) provided we are notified within two weeks of the purchase date. No refund will be given if services are terminated by us because of any breach of our terms and conditions.</p>



     <p>Terms and Conditions for the supply of other business services</p>



     <p>1. DEFINITIONS</p>



     <p>In these terms and conditions, the following words shall have the following meanings:</p>



     <p>"The Company" means FoodNear Company</p>



     <p>"Customer" means the person, company, partnership or other organisation placing an Order either on their own behalf as agent.</p>



     <p>"Order" means an instruction to carry out a Service (as defined below).</p>



     <p>"Regulated Search Report" means a report compiled by the Company after a search of local authority records.</p>



     <p>"Report" means a search report prepared by the Company or a Supplier as part of the Service.</p>



     <p>"Search Code" means the Search Code of Practice for Compilers and Retailers published by the&nbsp; Search Organisations.</p>



     <p>"Service" means the supply of search and indemnity services by the Company to the Customer including but not limited to Regulated Search Reports and other services from time to time and includes services ordered from a Supplier on behalf of the Customer and the dissemination of the information subsequently provided by the Supplier.</p>



     <p>"Supplier" means any organisation or third party who provides data or information of any form to the Company for the purposes of providing the Service.</p>



     <p>"Terms" means these terms and conditions of business.</p>



     <p>"we" or "us" or "our" or "ourselves" refers to the Company</p>



     <p>"you" or "your" or "yourself" refers to the Customer</p>



     <p>2. AGREEMENT</p>



     <p>•&nbsp; &nbsp; We agree to supply the Service to you subject to these Terms and you indicate your acceptance of these Terms when placing an order for the Service or when relying on the information in the Service.</p>



     <p>3. THE SERVICE</p>



     <p>We will provide the Service to you on the basis that you acknowledge and agree to the following:</p>



     <p>•&nbsp; &nbsp; The information in the Service reflects that available to us on the date the Service is produced.</p>



     <p>•&nbsp; &nbsp; The information contained in the Service can change on a regular basis and we cannot be responsible to you or to any person relying on the Service for any change in the information after the date on which the Service is produced and sent to you or for any inaccuracies, omissions or errors on a public register.</p>



     <p>•&nbsp; &nbsp; In providing the Services we will comply with the Search Code and will produce Regulated Search Reports with reasonable care and skill.</p>



     <p>4. LIABILITY AND INSURANCE</p>



     <p>•&nbsp; &nbsp; We shall not be liable for any acts or omissions of any party for whom we are not responsible.</p>



     <p>•&nbsp; &nbsp; We accept liability for death or personal injury arising from our negligence.</p>



     <p>•&nbsp; &nbsp; We accept liability (subject to these terms) for and have insurance in place to protect you or any person relying on the Service against negligence or errors by us or the relevant Supplier and with regard to information to be included in the Service.</p>



     <p>•&nbsp; &nbsp; The limit under our Professional Indemnity is £1000&nbsp; You acknowledge that a service from a Supplier may carry a different limit of indemnity.</p>



     <p>•&nbsp; &nbsp; Any claim relating to data or information obtained from a Supplier shall in the first instance be made against the Supplier, with such assistance from us as may reasonably be required, and only if such a claim cannot be made against the Supplier will you make a claim against us and in no circumstances shall the amount of such claim exceed the indemnity limit of the Supplier</p>



     <p>5. PRICE AND PAYMENT</p>



     <p>•&nbsp; &nbsp; The price payable for the Service is inclusive all&nbsp; taxes, unless otherwise stated.</p>



     <p>•&nbsp; &nbsp; Unless you have an account with us for payment of the Service, we must receive payment for the Service in full before the Service is produced.</p>



     <p>6. CONFIDENTIALITY</p>



     <p>•&nbsp; &nbsp; All instructions and information received by us shall be dealt with in strictest confidence.</p>



     <p>7. COPYRIGHT</p>



     <p>•&nbsp; &nbsp; Without prejudice to the licence for you and all others relying on the Service to use and take the benefit of the Service, the copyright and intellectual property rights in a Report shall remain our property save where such copyright and intellectual property rights are vested in any Supplier or other party.</p>



     <p>•&nbsp; &nbsp; You agree to respect and not to alter any trademark, copyright notice or trading name on any Service supplied by us.</p>



     <p>•&nbsp; &nbsp; You agree to indemnify us against any costs, claims and damage suffered by us as a result of any breach by you of the copyright terms in paragraphs 7 i) and 7 ii).</p>



     <p>8. RETENTION OF TITLE</p>



     <p>•&nbsp; &nbsp; Title to any Service supplied shall remain vested in us and shall not pass to you until the purchase price for the Service has been paid in full and received by us.</p>



     <p>9. DATA PROTECTION</p>



     <p>•&nbsp; &nbsp; We take our obligations of confidentiality and the protection of personal data very seriously. We will not, therefore, sell or make personal data available to any third party without your prior consent.</p>



     <p>TRANSACTIONS FROM CUSTOMERS PLACING AN ORDER ON THEIR OWN BEHALF</p>



     <p>•&nbsp; &nbsp; Where you request the Service for your personal benefit and not as a professional intermediary on behalf of others:</p>



     <p>•&nbsp; &nbsp; For the purposes of applicable data protection legislation, we will process any personal data you have provided in accordance with the Privacy Notice available on our website or on request from us. If you have any queries about the manner in which personal data will be processed by us or your rights in relation to such processing you should contact us via email or telephone, the details for both of which are accessible on this website.</p>



     <p>•&nbsp; &nbsp; You agree that, if you have provided to us personal data relating to a third party (1) you have in place all necessary appropriate consents and notices to enable lawful transfer of such personal data to us and (2) that you have brought to the attention of any such third party the Privacy Notice available on our website or otherwise provided a copy of it to the third party. You agree to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>TRANSACTIONS WITH PROFESSIONAL INTERMEDIARIES</p>



     <p>•&nbsp; &nbsp; Where you request the Service on behalf of others:</p>



     <p>• &nbsp; It is agreed that both parties will comply with the Data Protection Legislation and that this clause is in addition to, and does not replace, a party's obligations under the Data Protection Legislation. Data Protection Legislation means: (i) unless and until the GDPR is no longer directly applicable in the UK, the General Data Protection Regulation ((EU) 2016/679) and any national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK and then (ii) any successor legislation to the GDPR or the Data Protection Act 1998.</p>



     <p>• &nbsp; The parties acknowledge that for the purposes of the Data Protection Legislation, you are the data controller and we are the data processor.</p>



     <p>• &nbsp; You will ensure that you have all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to us for the duration and purposes of this agreement. You agree that you will provide us with such evidence as we require in relation to your compliance with these requirements and, further, to indemnify us in relation to all and any liabilities, penalties, fines, awards or costs arising from your non-compliance with these requirements.</p>



     <p>Details of the personal data that we process as a Data Processor are as follows:</p>



     <p>•&nbsp; &nbsp; The subject matter – The provision of the Service to the Customer</p>



     <p>•&nbsp; &nbsp; The duration of the Processing – Personal data is processed for the duration of the Service and retained subject to clause 9 iii) e. H below.</p>



     <p>•&nbsp; &nbsp; The nature and purpose of the Processing – We process personal data for the purpose of providing the Service to the Customer</p>



     <p>•&nbsp; &nbsp; The type of personal data being processed – Name and address</p>



     <p>•&nbsp; &nbsp; The categories of Data Subjects – Customers</p>



     <p>We shall, in relation to any personal data processed in the course of providing a service to you</p>



     <p>•&nbsp; &nbsp; process personal data only on your instructions (unless we have a legal obligation to process the personal data which, where permissible, we will have notified to you before performing the processing required);</p>



     <p>•&nbsp; &nbsp; have in place reasonable and proportionate technical and organisational measures to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data;</p>



     <p>•&nbsp; &nbsp; ensure that all personnel who have access to and/or process personal data are obliged to keep the personal data confidential;</p>



     <p>•&nbsp; &nbsp; not appoint a third-party processor without your consent, which shall be deemed to have been given if you have ordered a Service provided by a Supplier</p>



     <p>•&nbsp; &nbsp; not transfer any personal data outside of the European Economic Area without your prior written consent;</p>



     <p>•&nbsp; &nbsp; assist you, at your cost, in responding to any request from a Data Subject and in ensuring compliance with your obligations under the Data Protection Legislation with respect to security, breach notifications and impact assessments;</p>



     <p>•&nbsp; &nbsp; notify you without undue delay on becoming aware of a personal data breach;</p>



     <p>•&nbsp; &nbsp; on your written direction, delete or return personal data and copies thereof to you unless subject to a legal obligation to store the personal data; and</p>



     <p>•&nbsp; &nbsp; submit to audits requested by you.</p>



     <p>10. COMPLAINTS PROCEDURE</p>



     <p>•&nbsp; &nbsp; We&nbsp; are registered&nbsp; subscriber to the Search Code. A key commitment under the Code is that firms will handle any complaints both speedily and fairly. If you want to make a complaint, we will:</p>



     <p>• &nbsp; Acknowledge it within 5 working days of receipt.</p>



     <p>•&nbsp; &nbsp; Normally deal with it fully and provide a final response, in writing, within 20 working days of receipt.</p>



     <p>•&nbsp; &nbsp; Keep you informed by letter, telephone or e-mail, as you prefer, if we need more time.</p>



     <p>•&nbsp; &nbsp; Provide a final response, in writing, at the latest within 40 working days of receipt.</p>



     <p>• &nbsp; Liaise, at your request, with anyone acting formally on your behalf.</p>



     <p>11. GENERAL</p>



     <p>•&nbsp; &nbsp; If any term is held to be invalid or unenforceable, that provision or part of that provision shall be taken to be removed from these Terms and the remaining terms will continue in full force and effect.</p>



     <p>•&nbsp; &nbsp; These Terms shall be governed by English law and shall be subject to the jurisdiction of the English Courts.</p>
        </div>
        <Footer/>
        </div>

    )
}

export default TermsPage
