import React from 'react';
import {Parallax, ParallaxBanner, useParallax} from "react-scroll-parallax";
import mainMock from "../assets/mainMock.png"
import elCircle from "../assets/el-circle.png"
import doubleMock from '../assets/Mock2creens.png'
import "../App.scss"

export const Parallaxs = () => {
     return (
            <Parallax translateY={[20, -50]}    easing="easeInQuad" className="main-img">
                <img src={mainMock} alt={"mock"}  />
            </Parallax>
        );

}

export const ParallaxCircle = () => {
    return (
        <Parallax  rotate={[0, 360]}   easing="easeInQuad" className="main-img">
            <img src={elCircle} alt={"elCircle"}  />
        </Parallax>
    );

}
export const ParallaxDoubleMock = () => {
    return (
        <Parallax  translateY={[0, 40]}   easing="easeInQuad" className="image-mock">
            <img src={doubleMock} alt={"mock"}  className="phone-un"/>
        </Parallax>
    );

}


