import contact from "../assets/contact.png";
import React, {useState} from "react";
import '../App.scss'
import firebase from '../config/firebase-config'
import { getDatabase, ref, set } from "firebase/database";
import {TransitionAlerts} from "./alertModal";

export const ContactForm = () => {
    let [email, setEmail] = useState('')
    let [name, setName] = useState('')
    let [openAlert, setOpenAlert] = useState(false)

    const timeCollapseAlert = () =>{
        setOpenAlert(true)
        setTimeout(() => setOpenAlert(false), 4000);
    }

    const getTimeEpoch = () => {
        return new Date().getTime().toString();
    }

    const sendMessage = (e) =>{
        e.preventDefault();
        const database = getDatabase(firebase);
        try {
            const r = set(ref(database, 'messages/' + getTimeEpoch()), {
                site: 'Foodnear.io',
                name: name,
                email: email,
            })
            timeCollapseAlert()
            console.log(r)
        }
        catch (error){
            console.log(error)
        }

    }
    const updateInput = (e) =>{
      if(e.target.name == "name"){
          setName(e.target.value)
      }else if (e.target.name == "email"){
          setEmail(e.target.value)
      }else{
          console.log("error")
      }
    }
    return (
        <div className='contactForm'>
            <form
                onSubmit={sendMessage}>
                <h3>Direct form</h3>
                <p>
                    <input type="text" placeholder="Name" name="name" id="name"
                           onChange={updateInput}

                           required/>
                </p>
                <p>
                    <input type="email" placeholder="Email Address" name="email" id="email"
                           onChange={updateInput}
                           required/>
                </p>
                <button type="submit">Send</button>
                {/*{openAlert ? <TransitionAlerts setOpen={openAlert} />: null}*/}
              <TransitionAlerts setOpen={openAlert} />
            </form>
            <img className='contactImg' src={contact} alt='contact'/>

        </div>
        )

}
