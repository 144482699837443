import {Navigate, Route, Routes, Switch} from 'react-router-dom';
import React, { Suspense, Fragment, lazy } from 'react';
import Homepage from "./Pages/HomePage";
import TermsPage from "./Pages/Terms";
import LicencePage from "./Pages/LicencePage";


export const renderRoutes = (routes = []) => (

        <Routes>
            {routes.map((route, i) => {
                const Component = route.element;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        element={route.element}
                    />
                );
            })}
        </Routes>
)


const routes = [
    {
        exact: true,
        path: '/',
        element: <Homepage/>
    },
    {
        exact: true,
        path: '/terms',
        element: <TermsPage/>
    },
    {
        exact: true,
        path: '/licence',
        element: <LicencePage/>
    },
    {
        path: '*',
        exact: true,
        element: <Homepage/>
    }

]

export default routes;
