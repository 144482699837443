import '../App.scss'
import React, {useEffect, useRef} from "react";
import Collapsible from 'react-collapsible';
import {ParallaxBanner} from "react-scroll-parallax";
import {fadeIn, fadeInLeft, fadeInRight} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Card, Slide} from "@mui/material";


import menu_icon from '../assets/home.png'
import logo from '../assets/logo.png'
import facebook from '../assets/facebook-icon.png'
import linkedin from '../assets/linkedin-icon.png'
import reddit from '../assets/reddit-icon.png'
import telegram from '../assets/telegram-icon.png'
import twitter from '../assets/twitter-icon.png'
import contact from '../assets/contact.png'
import main from '../assets/mainMock.png'
import backgroundWaves from '../assets/background-waves.png'
import mockAppPreview from '../assets/mock-appPreview.png'
import mockAppDoublePreview from '../assets/Mock2creens.png'
import elCircle from '../assets/el-circle.png'
import faqImage from '../assets/faqPicture.svg'
import backgroundContact from '../assets/BackgroundContact.png'
import cashbackIcon from '../assets/@2/cashback.png'
import giftIcon from '../assets/@2/gift-dynamic-premium.png'
import trophyIcon from '../assets/trophy-dynamic-premium.png'
import recyclingIcon from '../assets/@2/trash-can-dynamic-premium.png'
import thumbUpIcon from '../assets/@2/thumb-up-dynamic-premium.png'
import rocketIcon from '../assets/@2/rocket-dynamic-premium.png'
import chatIcon from '../assets/@2/chat-text-dynamic-premium.png'


import {ParallaxCircle, ParallaxDoubleMock, Parallaxs} from "../components/ParallaxElement";
import AccountMenu from "../components/MenuElement"
import {TelegramButtonList} from "../components/buttonElement";
import {Helpukraine} from "../components/promoElement";
import {useIntersection} from "../helpers/useOnScreen"
import Typography from "@mui/material/Typography";
import {ContactForm} from "../components/directForm";
import {Link} from "react-router-dom";
import Header from "../components/headerElement";
import Footer from "../components/footerElement";
import Team from "../components/team";
import {userCheck} from "../helpers/credUser";

const createGuest = require('cross-domain-storage/guest');


const faq = [
    {
        trigger: 'Where does FoodNear work?',
        content: 'The FoodNear team is developing a decentralised app that can operate without external intervention anywhere in the world. There will be FoodNear offices for some countries. If you are interested in opening an office in your country or region, please send us an email and we will consider your application. If you would like to be one of the to volunteer, please fill in the application form below - DISABLED'
    },
    {
        trigger: 'What guarantees does the service provide?',
        content: 'We make every effort to ensure the safety of our customers, using user rewards and star rating system.  Our customers\' and trusted FoodNear experts\' reviews are the main guarantee of high-quality healthy food for everybody. This is a cornerstone priority for us.'
    },
    {
        trigger: 'What is the main goal of Foodnear?',
        content: <p>Goal #1: The aim is to change the food market, to change consumer attitudes towards home-cooked
            food, to reduce organic waste, to increase interest in healthy food and to give people who know how to cook
            well an opportunity to earn money.<br/>
            Goal #2: Monetise your culinary skills and rationalise your consumption.
            <br/>
            Goal #3: Create a platform that integrates into three main components:
            <br/>
            - attitudes towards consumption and healthy eating
            <br/>
            - philanthropy
            <br/>
            - Monetisation for participants.
            <br/>
            Goal #4: Food and humanitarian aid for the people of Ukraine.
        </p>
    },
    {
        trigger: 'Who can be a cooker?',
        content: 'Anyone who knows how to cook well and wants to monetise their culinary talents can register on our platform as a vendor.'
    },
    {
        trigger: 'Who makes deliveries?',
        content: 'Our team has developed many mechanisms to organise the delivery of customer orders. We enable sellers to hire employees to organise better and faster delivery and we also bring delivery services to our project.'
    }
]

const styles = {
    fadeInLeft: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
    },
    fadeInRight: {
        animation: 'x 3s',
        animationName: Radium.keyframes(fadeInRight, 'fadeInRight'),
        zIndex: 1,
    },
    circleStyle: {
        position: 'absolute',
        zIndex: 20,
        top: 0,
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
    }
}


const Homepage = () => {
    const [checked, setChecked] = React.useState(false);
    const ref = useRef();
    const isVisible = useIntersection(ref, '0px');


    return (

        <>
            <Helpukraine/>
            <div className="blackBorder">
                <div className='home'>
                    <ParallaxBanner
                        layers={[{
                            image: backgroundWaves, speed: -15, style: {
                                zIndex: '1'
                            }
                        }]}
                        className="aspect-[2/1] parallaxBannerFix"
                    >
                        <Header/>
                        <div className='main'>
                            <div className='main-content'>
                                <h1>A new era of food distribution</h1>
                                <p>The FoodNear platform working on opening new food market for all mankind</p>
                                <div className='links-main'>
                                    <IconButton onClick={() => window.open("https://twitter.com/FONfinance", "_blank")}><img
                                        src={twitter}/></IconButton>
                                    <IconButton
                                        onClick={() => window.open("https://www.facebook.com/FoodNear-105701982033715", "_blank")}><img
                                        src={facebook}/></IconButton>
                                    <IconButton
                                        onClick={() => window.open("https://www.linkedin.com/company/foodnear", "_blank")}><img
                                        src={linkedin}/></IconButton>
                                    <TelegramButtonList/>
                                    <IconButton
                                        onClick={() => window.open("https://www.reddit.com/user/FoodNear", "_blank")}><img
                                        src={reddit}/></IconButton>
                                </div>
                            </div>
                            <div className='main-img'>
                                <Parallaxs/>
                            </div>
                        </div>
                    </ParallaxBanner>
                </div>
                <div className='descriptionSection'>
                    <div className="white">
                        <h3>FoodNear App</h3>
                        <p>FoodNear Mobile App helps to avoid food waste, satisfy the demand for healthy homemade best
                            price meals for anyone. We connect meal cookers and customers on the same platform via Free
                            Mobile App using P2P payment system. The personal virtual restaurant is now available for
                            any person, chef, housewife, baker, who knows how to cook well, free of charge in any
                            countries. All meals that you cook at home, will be offered to a wide audience of potential
                            buyers and then purchased or free shared at your neighbourhood search option. Meal
                            presentations with photo, p2p chat, user rewards, reviews ans star rating - all of these
                            options are available for you in FoodNear App. Professional reviews, personal chats, P2P
                            safe payment solutions, digital token-based tips, QR code gift certificates, discounts, and
                            bonuses are offered for buyers and sellers of any country.</p>
                    </div>

                    <div className="yellow">
                        <h2 className="section-title">Whitelist</h2>
                        <p className="block-description">Be among the early users to test Foodnear app</p>
                        <Button className="get-beta"
                                onClick={() => window.open("https://app.foodnear.io/pages/app-in-beta.html", "_blank")}>Registration</Button>
                        <div className="abstraction">
                            {/*<img src="<?php echo get_template_directory_uri() ?>/img/get-token-abstraction.svg" alt="">*/}
                        </div>
                    </div>
                </div>
                <div className='videSection'>

                    <div className='video-card'>
                        <div className='video-header'>
                            <h3 style={{marginBottom: "0px"}}>Foodnear</h3>
                            <h5 style={{marginTop: "10px", textTransform : 'uppercase', fontWeight: 300}}>A new era of food distribution</h5>
                        </div>

                        <iframe  className='video' src="https://www.youtube.com/embed/f2Z_CG8wo4s"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div className='food-app' ref={ref}>
                    <div className='food-app-el'>
                        <Slide direction="right" in={isVisible}>
                            <div className='flex-left'>
                                <div className="text-place">
                                    <h3>Search food. Share food</h3>
                                    <p>Check the map to see what your neighbors cook and sell. Simply select and order
                                        any dish or
                                        pastry, pay online, and get your order.</p>
                                </div>
                                <div className="number-place">
                                    <p>01</p>
                                </div>

                            </div>
                        </Slide>
                        <Slide direction="left" in={isVisible} style={{transitionDelay: isVisible ? '500ms' : '0ms'}}>
                            <div className='flex-right'>
                                <div className="number-place">
                                    <p>02</p>
                                </div>
                                <div className="text-place">
                                    <h3>The task for a chief</h3>
                                    <p>You can create a request for cooking a specific dish. Just place a request and
                                        wait for
                                        responses from the chefs. Negotiate the details, pay, and receive your
                                        order.</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide direction="right" in={isVisible} style={{transitionDelay: isVisible ? '1000ms' : '0ms'}}>
                            <div className='flex-left'>
                                <div className="text-place">
                                    <h3>Free usage. No Fees. No Comission</h3>
                                    <p>Customers use the FoodNear platform completely free of charge. No commissions, no
                                        monthly
                                        fees.</p>
                                </div>
                                <div className="number-place">
                                    <p>03</p>
                                </div>
                            </div>
                        </Slide>
                        <Slide direction="left" in={isVisible} style={{transitionDelay: isVisible ? '1500ms' : '0ms'}}>
                            <div className='flex-right'>
                                <div className="number-place">
                                    <p>04</p>
                                </div>

                                <div className="text-place">
                                    <h3>Scripted nutritional rations</h3>
                                    <p>You no longer have to prepare a meal a day or a week in advance. Just indicate
                                        your
                                        preferences, and the app will select the best dishes for you and the nearest
                                        chefs. It will
                                        compile a diet for several days or even weeks in advance.</p>
                                </div>


                            </div>
                        </Slide>
                    </div>
                    <div className='food-app-el food-app-el-right'>
                        {/*<h3>FoodNear App</h3>*/}
                        {/*<ParallaxCircle/>*/}
                        {/*<img src={mockAppDoublePreview} style={styles.circleStyle}/>*/}
                        {/*<ParallaxCircle/>*/}
                        <ParallaxDoubleMock/>
                    </div>
                </div>
                <div className="earningSection">
                    <h3>Foodnear for earning</h3>
                    <div className="cardModule">
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Cashback</h3>
                            <p>Cashback system for customers</p>
                            <img src={giftIcon} alt={"cashback"}/>
                        </Card>
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Good practice</h3>
                            <p>Getting internal benefits for following the platform's tips</p>
                            <img src={recyclingIcon} alt={"recycling"}/>
                        </Card>
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Cookbook by pro cookers of platform</h3>
                            <p>The pro chefs can share their fabulous recipes all over the platform</p>
                            <img src={rocketIcon} alt={"rocket"}/>
                        </Card>
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Moderation</h3>
                            <p>For accurate and conscientious fulfillment of their obligations, moderators are going to
                                be awarded by bonuses</p>
                            <img src={chatIcon} alt={"moderation"}/>

                        </Card>
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Sell your meal</h3>
                            <p>Getting the opportunity to sell your food to a large audience</p>
                            <img src={cashbackIcon} alt={"recycling"}/>
                        </Card>
                        <Card sx={{minWidth: 275}} className="card">
                            <h3>Feedback</h3>
                            <p>Getting internal benefits for leaving feedback and writing reviews on the purchased
                                products</p>
                            <img src={thumbUpIcon} alt={"thumb up"}/>
                        </Card>
                    </div>

                </div>
                <div className="team">
                    <Team/>
                </div>
                <div className='faq'>
                    <h3>FAQ</h3>
                    <div className='faq-el'>
                        <img src={faqImage} alt={"faqImage"}/>
                    </div>
                    <div className='faq-el'>
                        {faq.map(el =>
                            <Collapsible transitionTime={300} key={faq.indexOf(el)} trigger={el.trigger}>
                                <p>{el.content}</p>
                            </Collapsible>
                        )}
                    </div>
                </div>
                <ParallaxBanner
                    layers={[{
                        image: backgroundContact, speed: -15, style: {
                            zIndex: '1'
                        }
                    }]}
                    className="aspect-[2/1]"
                >
                    <div className='contact'>

                        <div className='center'>
                            <h2>Contact FoodNear</h2>
                            <p>Learn more about FoodNear, interact with the FoodNear team, engage in community
                                discussions, and
                                have your say in shaping the future of decentralize food </p>
                            <div className='links-contact'>
                                <a href='https://twitter.com/FONfinance'><img src={twitter}/></a>
                                <a href='https://www.facebook.com/FoodNear-105701982033715'><img src={facebook}/></a>
                                <a href='https://www.linkedin.com/company/foodnear'><img src={linkedin}/></a>
                                <a href='https://t.me/foodnearcommunity'><img src={telegram}/></a>
                                <a href='https://www.reddit.com/user/FoodNear'><img src={reddit}/></a>
                            </div>
                            <ContactForm/>
                        </div>
                    </div>
                </ParallaxBanner>
                <Footer/>
            </div>
        </>
    )
}

export default Homepage
