import React from 'react';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.scss';
import App from './App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
)

