import * as React from 'react';
import '../App.scss'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import telegram from '../assets/telegram-icon.png'
import IconButton from "@mui/material/IconButton";
import {ButtonGroup, ClickAwayListener, MenuList, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

export const TelegramButtonList = () => {
    const styles = {
       popoverItems: {
           display: "inline",
       }
    }
    const buttons = [
        <Button key="one">Announcement channel</Button>,
        <Button key="two">Community Discussion</Button>,
        // <Button key="three">Three</Button>,
    ];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    let optionsButtons = {
        label: ["📣Announcement Channel", "🚀Community Discussion"],
        url: ["https://t.me/foodnear", "https://t.me/foodnearcommunity"]
    }
    return (
            <PopupState variant="popover" >
                {(popupState) => (
                    <div className="links-main-telegram">
                        <IconButton  {...bindTrigger(popupState)}>
                            <img src={telegram}/>
                        </IconButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                                    {/*<ButtonGroup*/}
                                    {/*    orientation="vertical"*/}
                                    {/*    aria-label="vertical outlined button group"*/}
                                    {/*>*/}
                                    {/*    {buttons}*/}
                                    {/*</ButtonGroup>*/}
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {optionsButtons.label.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                disabled={index === 2}
                                                // selected={index === selectedIndex}
                                                onClick={() => window.open(optionsButtons.url[index], "_blank")}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </div>
                )}
            </PopupState>
        );
}
