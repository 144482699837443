import React from "react";
import UkraineMark from '../assets/gerb.svg'
import '../App.scss'
import Button from "@mui/material/Button";

export const Helpukraine = () =>{
    return(
        <div className='promo'>
            <div className="russkiyVoeniyKorablIdiNahuy-card">
                <div>
                    <div className="promo-image">
                        <img src={UkraineMark} alt={"UkraineMark"}/>
                    </div>
                    <div className="promo-text">
                        <p>We invite<span> you </span>to join our project to prevent a humanitarian catastrophe in <span>Ukraine</span></p>
                    </div>
                </div>
                <div className="promo-button">
                    <a href="https://www.helpukraine.community/en" target="_blank"><Button>Help Ukraine</Button></a>
                </div>
            </div>
        </div>
    )
}
