import React, {useEffect, useState} from "react";
import Schabratko from '../assets/team-images/oleksandr_shabratko.png';
import CEO from '../assets/team-images/maksym_teodorovych.png';
import CTO from '../assets/team-images/aleksandr_lipkov.png';
import CFO from '../assets/team-images/yaroslav_naumkyn.png';
import CMO from '../assets/team-images/maksym_baburin.png';
import CIO from '../assets/team-images/sergei_kachovskyi.png';
import CAO from '../assets/team-images/ilya_zhykhariev.png';
import CISO from '../assets/team-images/rostislav_gogolauri.png';
import Twitter from '../assets/twitter.svg';
import Linkedin from '../assets/linkedin.svg';
import Facebook from '../assets/facebook.svg';
const Team = () => {



  return (

<section className="our-team">
  <div className="section-wrapper">
    <h2 className="section-title">Our Team</h2>

    <div className="team-list">
      <div className="item">
        <div className="image">
          <img className="team-member" src={CEO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/maksym-teodorovych/" target="_blank"><img src={Linkedin} alt=""/> </a>
              <br/>
              <a href="https://www.facebook.com/maksymteo" target="_blank"><img src={Facebook} alt=""/></a>
              <br/>
              <a href="https://twitter.com/Maks_Teod" target="_blank"><img src={Twitter} alt=""/></a>
            </div>
        </div>
        <div className="name">Max Teodorovych</div>
        <div className="position">CEO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CTO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/aleksandr-lipkov-259195190/" target="_blank"><img src={Linkedin} alt=""/></a>
            </div>
        </div>
        <div className="name">Alex Lipkov</div>
        <div className="position">CTO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CFO} alt=""/>

            <div className="icon">
            </div>
        </div>
        <div className="name">Yaroslav Naumkin</div>
        <div className="position">CFO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CMO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/maximbaburin/" target="_blank"><img src={Linkedin} alt=""/></a>
            </div>
        </div>
        <div className="name">Max Baburin </div>
        <div className="position">CMO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={Schabratko} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/shabratko" target="_blank"><img src={Linkedin} alt=""/></a>
            </div>
        </div>
        <div className="name">Oleksandr Shabratko</div>
        <div className="position">CBDO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CIO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/sergei-kachovskyi-3796941ba/" target="_blank"><img src={Linkedin} alt=""/></a>
            </div>
        </div>
        <div className="name">Sergei Kachovskyi</div>
        <div className="position">CIO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CAO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/ilya-z-07a7aa230/" target="_blank"><img src={Linkedin} alt=""/></a>
            </div>
        </div>
        <div className="name">Ilya Zhykhariev</div>
        <div className="position">CAO</div>
      </div>
      <div className="item">
        <div className="image">
          <img className="team-member" src={CISO} alt=""/>

            <div className="icon">
              <a href="https://www.linkedin.com/in/mapi" target="_blank"><img src={Linkedin} alt=""/> </a>
            </div>
        </div>
        <div className="name">Rostislav Gogolauri</div>
        <div className="position">CISO</div>
      </div>


    </div>
  </div>
</section>
    )
  }
export default Team