import { initializeApp } from 'firebase/app';


const CONFIG = {

    firebase: {
        apiKey: 'AIzaSyCQ6l4cWJGRS4MtirUnnEAm_XW-W-f-Sro',
        authDomain: 'foodneardashboard.firebaseapp.com',
        projectId: 'foodneardashboard',
        storageBucket: 'foodneardashboard.appspot.com',
        messagingSenderId: '26625884957',
        appId: '1:26625884957:web:9555849c490196790dd7f4',
        measurementId: 'G-77K5VK4MNR'
    }
}
const firebase = initializeApp(CONFIG.firebase);

export default firebase;
