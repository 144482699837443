import React, {useEffect, useState} from "react";
import logo from "../assets/logo.png";
import AccountMenu from "./MenuElement";
import {Link} from "react-router-dom";
import {userCheck} from "../helpers/credUser";

const Header = () => {
    let [userEmail, setUserEmail] = useState(null)
    useEffect(() => {
        let dataUser = userCheck()

        if (dataUser !== null){
            setUserEmail(dataUser)
        }

    }, [])
    return(
        <div className="header">
           <Link to='/'><img className='logo' src={logo} alt='logo'/></Link>
            <AccountMenu userEmail={userEmail}/>
        </div>
    )
}
export default Header
