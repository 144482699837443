import React from "react";
import logo from "../assets/logo.png";
import {Link} from "react-router-dom";

const Footer = () =>{
    return(
        <footer className='footer'>
            <div className='footer-info'>
                <a href="https://foodnear.io">
                    <img src={logo} alt='logo'/>
                </a>
                <p>
                    <a href='#'>contact@foodnear.io</a>
                </p>
                <div className="divider">
                    <p>© 2022 FoodNear #089892938</p>
                </div>

            </div>
            {/*<div className='footer-links'>*/}
            {/*    <a href='#'>About</a>*/}
            {/*    <a href='#'>Ecosystem</a>*/}
            {/*    <a href='#'>Whitepaper</a>*/}
            {/*    <a href='#'>Community</a>*/}
            {/*    <Link to='/terms'>Terms</Link>*/}
            {/*    <Link to='/licence'>Licence</Link>*/}
            {/*</div>*/}
            <div className='footer-links'>
               <li>
                   <h4>Developer</h4>
                   <Link to="/">Github</Link>
                   <Link to="/">Whitepaper</Link>
                   <Link to="/">Audit Report</Link>
               </li>
                <li>
                    <h4>Community</h4>
                    <a target="_blank" href="https://t.me/foodnearcommunity">Telegram</a>
                    <a target="_blank" href="https://twitter.com/FONfinance">Twitter</a>
                    <a target="_blank" href="https://www.reddit.com/user/FoodNear">Reddit</a>
                    <a target="_blank" href="https://www.facebook.com/FoodNear-105701982033715">Facebook</a>
                </li>
                <li>
                    <h4>Tools</h4>
                    <a href="https://app.foodnear.io/pages/dashboard.html">Dashboard</a>
                    <a href="https://app.foodnear.io/pages/sign-in.html">Sign in</a>
                    <a href="https://app.foodnear.io/pages/sign-up.html">Sign up</a>
                </li>
                <li>
                    <h4>About</h4>
                    <a href="https://medium.com/@foodnear">Medium</a>
                    <Link to="/">Media Kit</Link>
                    <Link to="/licence">Licence</Link>
                    <Link to="/terms">Terms</Link>
                </li>
            </div>
        </footer>
    )
}
export default Footer
