import Box from "@mui/material/Box";
import {Alert, Collapse} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {useState} from "react";

function CloseIcon(props) {
    return null;
}

CloseIcon.propTypes = {fontSize: PropTypes.string};
export  const TransitionAlerts = ({setOpen}) => {


    return (
        <Box sx={{ width: '100%' }} style={{marginTop: 10, marginBottom: 10}}>
            <Collapse in={setOpen}>
                <Alert

                    sx={{ mb: 2 }}
                >
                    Data was sent successfully!
                </Alert>
            </Collapse>
        </Box>
    );
}
